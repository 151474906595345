<template>
  <div>
    <Card :bordered="false" dis-hover class="ivu-mt">
      <Row type="flex">
        <Col v-bind="grid">
          <Button v-auths="[`${$config.uniquePrefix}users:group:create`]" type="primary" icon="md-add" @click="handleCreateGroup">添加分组</Button>
        </Col>
      </Row>
      <Table
        :columns="columns"
        :data="groupLists"
        ref="table"
        class="l-m-t25"
        :loading="loading"
        highlight-row
        no-userFrom-text="暂无数据"
        no-filtered-userFrom-text="暂无筛选结果">
        <template slot-scope="{ row }" slot="icons">
          <div class="tabBox_img" v-viewer>
            <img v-lazy="row.icon" />
          </div>
        </template>
        <template slot-scope="{ row }" slot="action">
          <a @click="handleEdit(row.id)">修改</a>
          <Divider type="vertical" />
          <a class="l-color-error" @click="handleDelete(row, '删除分组', index)">删除</a>
        </template>
      </Table>

      <div class="acea-row row-right l-m-t25">
        <Page :total="total" show-elevator show-total @on-change="handlePageChange" :page-size="searchWhere.limit" />
      </div>
    </Card>
  </div>
</template>

<script>
import mixins from '@/mixins'
import {
  getGroupListApi
} from '@/api/users'

export default {
  name: 'index',
  mixins: [mixins],
  data () {
    return {
      searchWhere: {
        page: 1,
        limit: 15
      },
      // 表格数据
      groupLists: [],
      columns: [
        {
          title: 'ID',
          key: 'id',
          width: 80,
          align: 'center'
        },
        {
          title: '分组名称',
          key: 'group_name',
          align: 'center'
        },
        {
          title: '操作',
          slot: 'action',
          fixed: 'right',
          width: 120,
          align: 'center'
        }
      ]
    }
  },
  mounted () {
    this.getGroupListRequest()
  },
  methods: {
    // 获取分组列表
    getGroupListRequest () {
      this.loading = true
      getGroupListApi().then(res => {
        this.groupLists = res.data.list
        this.total = res.data.total
        this.loading = false
      }).catch(err => {
        this.$Message.error(err.msg)
        this.loading = false
      })
    },
    // 处理添加分组
    handleCreateGroup () {},
    // 分组编辑
    handleEdit () {},
    // 分组删除
    handleDelete () {},
    // 处理分页搜索
    handlePageChange (index) {}
  }
}
</script>

<style scoped>

</style>
